import React, {useState} from 'react'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../img/logo.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#f1f1f1",
    },
    spacer: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
      
    },
}));

const Navbar = () => {
  
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" 
            color="transparent"
            >
                <Toolbar>
                <div className={classes.title}>
                    <Link to="/">
                        <img src={logo} alt="Dance Clarity"/>
                    </Link>
                </div>
                <Hidden lgUp>
                <div className={classes.spacer}></div>

                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="nav-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}>
                        <MenuItem onClick={handleClose}><Button color="inherit" href="/register">Register</Button></MenuItem>
                    </Menu>
                </Hidden>
                <Hidden mdDown>                    
                    <div className={classes.spacer}></div>
                    <Button color="inherit" href="/register">Register</Button>
                </Hidden>
                {
                //<Button color="inherit" href="/about">For Students</Button>
                //<Button color="inherit" href="/about">For Studios</Button>
                }
                
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Navbar
