import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Logo from "../img/logo.png";

const useStyles = makeStyles(theme => ({
    footerContainer: {
        backgroundColor: "#f1f1f1",
        padding: "1rem",
        paddingBottom: "3rem",
        borderTopColor: "#868686", 
        borderTopStyle: "solid", 
        borderTopWidth: "1px",
    },
    itemTextDiv: {
        overflow: "hidden", 
        padding: '1rem',
    },
    icon: {
        fontSize: "48px",
    },
    itemText: {
        fontSize: "24px",
        color: "#000",
        lineHeight: "1.1",
        marginBottom: "20px",
    },
    copyrightText: {
        width: "100%", 
        textAlign: "center",
        paddingTop: "1rem",    
    }
}));

const Footer = () => {
    
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={classes.footerContainer}>
            <Container>
                <Grid container>
                    <Grid item xs={6} lg={3}>
                        <img src={Logo} alt="Dance Clarity Logo"/>
                    </Grid>
                    <Grid item xs={false} lg={3}>
                        
                    </Grid>
                    <Grid container item xs={6} alignItems="center">
                        <Grid item xs={2} md={4}></Grid>
                        <Grid item xs={5} md={4}>
                            {
                            //<Link href="/privacy">About</Link>
                            }
                        </Grid>
                        <Grid item xs={5} md={4}>
                            <Link href="/privacy">Privacy</Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div" className={classes.copyrightText}>© 2020 DanceClarity, All Rights Reserved </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
}

export default Footer;